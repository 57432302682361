// src/GAListener.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logPageView } from "./ga";

const GAListener = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return children;
};

export default GAListener;
