import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-black text-gray-300 max-w-7xl mx-auto py-12 px-6 mt-32">
      <h1 className="text-4xl font-bold text-white mb-8">Privacy Policy</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          1. Introduction
        </h2>
        <p>
          Welcome to DashPoint! This Privacy Policy explains how DashPoint
          ("we," "us," or "our") collects, uses, discloses, and protects your
          personal data when you use our website and mobile application
          (collectively, the "Services"). By accessing or using our Services,
          you agree to the collection and use of information in accordance with
          this policy.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          2. Information We Collect
        </h2>
        <p>We may collect and process the following types of personal data:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>
            <strong>Personal Identification Information:</strong> Name, email
            address, phone number, and other contact details.
          </li>
          <li>
            <strong>Account Information:</strong> Username, password, and other
            account-related information.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you use our
            Services, including the features you use and the time you spend on
            the app.
          </li>
          <li>
            <strong>Device Information:</strong> IP address, browser type,
            operating system, device identifiers, and other technical details.
          </li>
          <li>
            <strong>Location Data:</strong> Geographical location data obtained
            through your device (with your consent).
          </li>
          <li>
            <strong>Payment Information:</strong> If applicable, payment details
            processed through third-party payment providers.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          3. How We Use Your Information
        </h2>
        <p>We use the collected data for various purposes, including:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>
            <strong>To Provide and Maintain Our Services:</strong> Ensuring that
            our Services function correctly and efficiently.
          </li>
          <li>
            <strong>To Improve Our Services:</strong> Analyzing usage patterns
            to enhance user experience and develop new features.
          </li>
          <li>
            <strong>To Communicate with You:</strong> Sending updates,
            newsletters, marketing materials, and responding to your inquiries.
          </li>
          <li>
            <strong>To Manage Your Account:</strong> Handling account creation,
            authentication, and security.
          </li>
          <li>
            <strong>To Process Transactions:</strong> Managing payments and
            transactions securely through third-party providers.
          </li>
          <li>
            <strong>To Ensure Security:</strong> Protecting against unauthorized
            access, fraud, and other security threats.
          </li>
          <li>
            <strong>To Comply with Legal Obligations:</strong> Fulfilling legal
            requirements and responding to lawful requests.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          4. Legal Basis for Processing
        </h2>
        <p>
          We process your personal data based on the following legal grounds:
        </p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>
            <strong>Consent:</strong> You have given clear consent for us to
            process your personal data for specific purposes.
          </li>
          <li>
            <strong>Contractual Necessity:</strong> Processing is necessary for
            a contract we have with you or to take steps to enter into a
            contract.
          </li>
          <li>
            <strong>Legal Obligation:</strong> Processing is necessary for
            compliance with a legal obligation to which we are subject.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> Processing is necessary for
            our legitimate interests, provided your rights do not override those
            interests.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          5. Sharing Your Information
        </h2>
        <p>We may share your information in the following circumstances:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>
            <strong>With Service Providers:</strong> Third-party vendors who
            perform services on our behalf, such as payment processing, data
            analysis, email delivery, hosting services, and customer service.
          </li>
          <li>
            <strong>For Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of all or a portion of our assets.
          </li>
          <li>
            <strong>With Affiliates:</strong> Our affiliates and subsidiaries
            for purposes consistent with this Privacy Policy.
          </li>
          <li>
            <strong>With Your Consent:</strong> We may share your information
            with third parties when you have given us explicit consent to do so.
          </li>
          <li>
            <strong>For Legal Reasons:</strong> If required to do so by law or
            in response to valid requests by public authorities.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          6. Data Security
        </h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal data when you enter, submit, or access your personal
          information. These measures include encryption, access controls, and
          secure storage solutions. However, no method of transmission over the
          internet or electronic storage is 100% secure, and we cannot guarantee
          absolute security.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          7. Data Retention
        </h2>
        <p>
          We retain your personal data only for as long as necessary to fulfill
          the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law. When your personal
          data is no longer needed, we will securely delete or anonymize it.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          8. Your Rights
        </h2>
        <p>
          Under the UK GDPR, you have the following rights regarding your
          personal data:
        </p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>
            <strong>Right to Access:</strong> You can request access to the
            personal data we hold about you.
          </li>
          <li>
            <strong>Right to Rectification:</strong> You can request corrections
            to any inaccurate or incomplete data.
          </li>
          <li>
            <strong>Right to Erasure:</strong> You can request the deletion of
            your personal data under certain conditions.
          </li>
          <li>
            <strong>Right to Restrict Processing:</strong> You can request the
            limitation of processing your personal data.
          </li>
          <li>
            <strong>Right to Data Portability:</strong> You can request the
            transfer of your personal data to another service provider.
          </li>
          <li>
            <strong>Right to Object:</strong> You can object to the processing
            of your personal data for specific purposes.
          </li>
        </ul>
        <p className="mt-4">
          To exercise these rights, please contact us using the contact
          information provided below.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          9. Children's Privacy
        </h2>
        <p>
          Our Services are not intended for individuals under the age of 16. We
          do not knowingly collect personal data from children under 16. If we
          become aware that we have inadvertently received personal data from a
          child under 16, we will take steps to delete such information as soon
          as possible.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          10. International Data Transfers
        </h2>
        <p>
          Your information, including personal data, may be transferred to and
          maintained on servers located outside of your country of residence.
          These countries may have data protection laws that differ from those
          in your country. We take steps to ensure that your data is treated
          securely and in accordance with this Privacy Policy, regardless of
          where it is processed.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          11. Changes to This Privacy Policy
        </h2>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page and
          updating the "Last Updated" date at the top of this policy. You are
          advised to review this Privacy Policy periodically for any changes.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold text-white mb-4">
          12. Contact Us
        </h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <p className="mt-4">
          <strong>Email:</strong>{" "}
          <a
            href="mailto:support@dashpoint.uk"
            className="text-blue-400 underline"
          >
            support@dashpoint.uk
          </a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
