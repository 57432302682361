// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Terms from "./pages/Terms";
import Cookie from "./pages/Cookie";
import Privacy from "./pages/Privacy";
import HeroQR from "./components/Hero_QR";
import { initGA } from "./ga";
import GAListener from "./GAListener";

function App() {
  useEffect(() => {
    initGA();
  }, []);

  return (
    <Router>
      <GAListener>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/cookie" element={<Cookie />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/qr" element={<HeroQR />} />
          </Routes>
          <Footer />
        </div>
      </GAListener>
    </Router>
  );
}

export default App;
