// src/ga/index.js
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-304S97EEJ5"; // Replace with your actual Measurement ID

export const initGA = () => {
  ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};

export const logEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
