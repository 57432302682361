// src/components/Hero.js
import React, { useState, useEffect } from "react";
import dp1 from "../assets/dp-1.jpg";
import { logEvent } from "../ga";

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [dp1];

  useEffect(() => {
    // Change image every second
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [images.length]);

  const handleButtonClick = () => {
    // Log an event when the "Coming Soon" button is clicked
    logEvent("Hero Section", "Button Clicked", "Coming Soon Button");
    window.open(
      "   https://apps.apple.com/gb/app/dashpoint/id6739945708",
      "_blank"
    );
    // Additional button click logic can go here
  };

  return (
    <section
      className="bg-black text-white flex items-center justify-center px-6 relative"
      style={{ minHeight: "calc(100vh - 80px)" }}
    >
      <div className="max-w-7xl w-full mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8">
          {/* Text Section */}
          <div className="max-w-lg text-center md:text-left mt-32 md:mt-16">
            <h2 className="text-5xl md:text-6xl font-bold leading-tight mb-6">
              Run Together, Earn DashPoints.
            </h2>
            <ul className="space-y-3 mb-6 text-2xl">
              <li>🏃‍♂️ Discover weekly 5km running routes</li>
              <li>📍 Explore new routes around Newcastle</li>
              <li>🎉 Meet new people and earn DashPoints</li>
            </ul>
            <p className="text-lg md:text-xl mb-6">
              Discover surprise starting points and connect with your DashPoint community for unforgettable journeys. Every run offers new connections, fresh challenges, and plenty of DashPoints!
            </p>
            <button
              className="bg-[#86F04D] text-black py-3 px-6 text-lg md:text-xl font-bold rounded-full"
              onClick={handleButtonClick}
            >
              Download on the app store
            </button>
          </div>

          {/* Image Section */}
          <div className="flex justify-center items-center md:w-1/2 w-full h-auto">
            <img
              src={images[currentImageIndex]} // Dynamically display the current image
              alt={`DashPoint App - Image ${currentImageIndex + 1}`}
              className="rounded-lg shadow-xl object-contain"
              style={{
                width: "100%", // Full width on mobile
                maxWidth: "300px", // Limit max width for better responsiveness
                height: "auto", // Adjust height automatically
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
